
import axiosPost from './axiosPost';

export const gateMonitorSemanaGet = async (arg) => {
    //args: merchand id, hooli token, sessionID, serviceURL
    //Retrieve monitor zones
    const name = 'Monitor Semana'
    let result
    // Where does merchandId come from? 
    const { merchantId, puestoSelect } = arg

    const body = {
        "service": "GateMonitorSemanaGet",
        "params": {
            "merchantId": merchantId,
            "gateLugarId": puestoSelect
        }
    }

    try {
        result = await axiosPost('Y', body, arg)
    } catch (err) {
        console.log(err)
    }

    if (!result.data || result.data === null) {
        console.log(`Error getting ${name}`)
    }

    return result.data.result;
}

export default gateMonitorSemanaGet;