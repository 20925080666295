import { useEffect, useState, useContext } from 'react';

//Material UI components
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

//Contexts
import UserContext from '../context/userContext';

//Services
import { gateMonitorZonaGet } from '../services/GateMonitorZonaGet';
import gateMonitorLugarGet from '../services/GateMonitorLugarGet';
import gateMonitorSemanaGet from '../services/GateMonitorSemanaGet';

export default function DropSelect(props) {
    const {onSemanaChange, onPuestoChange} = props;

    // Zone (objetivos)hooks
    const [zoneList, setZoneList] = useState([]);
    const [zoneSelect, setZoneSelect] = useState(null);

    //Puestos (lugares) hooks
    const [puestoList, setPuestoList] = useState([]);
    const [puestoSelect, setPuestoSelect] = useState(null);
    const [filteredPuestoList, setFilteredPuestoList] = useState([]);

    //Semanas hooks
    const [semanaList, setSemanaList] = useState([]);
    const [semanaSelect, setSemanaSelect] = useState(null);

    //User data context
    const userData = useContext(UserContext);

    let classes = useStyles()

    useEffect(() => {
        if (userData) {
            // Promise all corre multi request simultaneo
            Promise.all([gateMonitorZonaGet(userData), gateMonitorLugarGet(userData)]).then((responses) => {
                // Lo primero q ingresa son las zonas
                setZoneList(responses[0])
                setPuestoList(responses[1])
            });
        }

    }, [userData])

    useEffect(() => {
        if (zoneSelect) {
            // Filtrar lista de puestos en base a la zona elegida
            setFilteredPuestoList(
                puestoList.filter((item) => {
                    return item.gateZonaId === zoneSelect
                })
            )
        }
    }, [zoneSelect])

    useEffect(() => {
        if (puestoSelect) {
            onPuestoChange(puestoSelect)

            const newData = { ...userData, puestoSelect };

            (async function retrieveSemanas() {
                let response = await gateMonitorSemanaGet(newData);
                await setSemanaList(response);
            })();
        }
    }, [puestoSelect])

    useEffect(() => {
        if (semanaSelect) {
            onSemanaChange(semanaSelect);
        }
    }, [semanaSelect])

    return (
        <div className={classes.container}>
            <div>
                <p className={classes.title}>Objetivo</p>
                <FormControl className={classes.longForrmControl}>
                    <InputLabel id="demo-simple-select-label">Seleccionar</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={zoneSelect}
                        onChange={(e) => { setZoneSelect(e.target.value) }}
                        placeholder={'Seleccionar Objetivo'}
                    >
                        {zoneList.map((item) => { return (<MenuItem value={item.gateZonaId}>{item.gateZonaNombre}</MenuItem>) })}
                    </Select>
                </FormControl>
            </div>

            <div >
                <p className={classes.title}>Puesto</p>
                <FormControl className={classes.longForrmControl}>
                    <InputLabel id="demo-simple-select-label">Seleccionar</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={puestoSelect}
                        onChange={(e) => { setPuestoSelect(e.target.value) }}
                        placeholder={'Seleccionar Puesto'}
                    >
                        {filteredPuestoList.map((item) => { return (<MenuItem value={item.gateLugarId}>{item.gateLugarNombre}</MenuItem>) })}
                    </Select>
                </FormControl>
            </div>

            <div >
                <p className={classes.title}>Semana</p>
                <FormControl className={classes.shortFormControl}>
                    <InputLabel id="demo-simple-select-label">Seleccionar</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={semanaSelect}
                        onChange={(e) => { setSemanaSelect(e.target.value) }}
                    >
                        {semanaList.map((item) => {
                            return (<MenuItem value={item.fechaDesde}>{"LU " + item.fechaDesde.substr(8, 2) + "/" + item.fechaDesde.substr(5, 2) + " a DO " + item.fechaHasta.substr(8, 2) + "/" + item.fechaHasta.substr(5, 2)}</MenuItem>)
                        })}
                    </Select>
                </FormControl>
            </div>
        </div>
    )
}

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 32,
        marginLeft: 42,
        marginRight: 42,
        height: 105,
        marginTop: 10
    },
    title: {
        color: '#707070',
        fontSize: 18,
        marginBottom: 3,
        fontWeight: 'bold'
    },
    longForrmControl: {
        width: 380
    },
    shortFormControl: {
        width: 240
    }
})
