import { fallbackURL } from "./const/FallbackURL";

export const checkAuth = () => {
    if (localStorage.getItem('hooliToken') && localStorage.getItem('sessionId') && localStorage.getItem('merchantId')) {
        return {
            hooliToken: localStorage.getItem('hooliToken'),
            sessionId: localStorage.getItem('sessionId'),
            merchantId: localStorage.getItem('merchantId')
        }
    };

    if (window.location.search) {
        const urlParams = new URLSearchParams(window.location.search);
        const sessionId = urlParams.get('session');
        const hooliToken = urlParams.get('token');
        const merchantId = urlParams.get('merchantId');

        localStorage.setItem('sessionId', sessionId);
        localStorage.setItem('hooliToken', hooliToken);
        localStorage.setItem('merchantId', merchantId);

        return {
            hooliToken: localStorage.getItem('hooliToken'),
            sessionId: localStorage.getItem('sessionId'),
            merchantId: localStorage.getItem('merchantId')
        }
    }

    return window.location.assign(fallbackURL);
};