import MonitorGate from './Views/Monitor';
import { UserProvider } from './context/userContext';
import { useEffect, useState } from 'react';
import { checkAuth } from './checkAuth';
import { serviceURL } from './const/ServiceURL';

function App() {

    const [user, setUser] = useState(null)

    useEffect(() => {

        (async function myAsync() {
            const { hooliToken, sessionId, merchantId } = checkAuth() as { hooliToken: string, sessionId: string, merchantId: string };
            if(window.location.search) window.history.replaceState({}, document.location.pathname, '/');
            //@ts-ignore
            setUser({ hooliToken, sessionId, merchantId, serviceURL })
        })();

    }, [])

    return (
        <div>
            <UserProvider value={user}>
                <MonitorGate />
            </UserProvider>
        </div>
    );
}

export default App;