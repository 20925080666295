import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Websocket from 'react-websocket';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import moment from 'moment';

import pinLogo from '../Views/pin.svg';
import { useEffect, useState, useContext, useRef } from 'react';


//Monitor grilla service
import gateMonitorGrillaGet from '../services/GateMonitorGrillaGet';
import UserContext from '../context/userContext';
import {AlertTitle} from "@material-ui/lab";
import { serviceURL } from '../const/ServiceURL';

//Color dot
function dot(color) {
    if (color === 'B') return null
    return (<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24"><circle fill={`${COLORS[color]}`} cx="12" cy="12" r="8" /></svg>)

}

const COLORS = {
    'V': '#63D1A2',
    'R': '#D44141',
    'A': '#FCC020'
}

let interval;

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default function GateMonitorGrilla(props) {
    const { semanaSeleccionada, puestoSeleccionado } = props;
    const [mensajeMostrado, setMensajeMostrado] = useState([]);
    const [mostrarComo, setMostrarComo] = useState(null);

    //User data context
    const userData = useContext(UserContext);

    //Grilla data
    const [rows, setRows] = useState([])

    const classes = useStyles();

    const tabla = useRef(null);

    const fetchGrilla = async () => {
        if (semanaSeleccionada && puestoSeleccionado) {
            const { hooliToken, sessionId } = userData;

            const payload = { hooliToken, sessionId, gateLugarId: puestoSeleccionado, fechaDesde: semanaSeleccionada, serviceURL };

            let data = await gateMonitorGrillaGet(payload)

            setRows(data)
        }
    }

    useEffect(() => {
        if (semanaSeleccionada && puestoSeleccionado) {
            fetchGrilla();
            if (interval) {
                clearInterval(interval)
                interval = null;
            }
            interval = setInterval(fetchGrilla, 1000 * 30);
        }

    }, [semanaSeleccionada, puestoSeleccionado])

    useEffect(() => {
        return () => {
            if (interval) {
                clearInterval(interval)
                interval = null;
            }
        };
    }, []);

    useEffect(() => {
        // Hacer scroll en la tabla hasta la fila 'presente'
        goToCurrent()

    }, [rows]);

    const goToCurrent = () => {
        const filaIdx = rows.findIndex(r => r.gateMonitorGrillaPresente === 'Y');
        if (filaIdx !== -1) {
            tabla.current.scrollTo({
                top: 67 * filaIdx,
                behavior: "smooth"
            })
        }
    }

    const handleWebsocketResponse = (data) => {
        fetchGrilla();
        clearInterval(interval)
        setInterval(fetchGrilla, 1000 * 30);
        const logData = JSON.parse(data);
        if (logData.gateCheckInOut === 'I') {
            setMensajeMostrado([
                `Check In - ${logData.usuarioApellidoYNombre} (${logData.usuarioNroDeDocumento})`,
                `Lugar: ${logData.gateLugarNombre} Hora: ${moment(logData.gateLugarUsuarioEntradaR).format("HH:mm")}`
            ]);
        } else {
            setMensajeMostrado([
                `Check Out - ${logData.usuarioApellidoYNombre} (${logData.usuarioNroDeDocumento})`,
                `Lugar: ${logData.gateLugarNombre} Hora: ${moment(logData.gateLugarUsuarioSalidaR).format("HH:mm")}`
            ]);
        }
        switch (logData.gateCheckInOutStatus) {
            case 'V':
                setMostrarComo('success');
                return;
            case 'A':
                setMostrarComo('warning');
                return;
            case 'R':
                setMostrarComo('error');
                return;
            default:
                return
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setMostrarComo(null);
    };

    return (
        <div className={classes.mainContainer}>
            <TableContainer component={Paper} className={classes.tableContainer} ref={tabla}>
                <Table stickyHeader className={classes.table} size="small" aria-label="a dense table">
                    <TableHead >
                        <TableRow>
                            <TableCell className={classes.tableHead}>Identificación</TableCell>
                            <TableCell style={{width: '100px'}} className={classes.tableHead} align="left">Día</TableCell>
                            <TableCell style={{width: '80px'}} className={classes.tableHead} align="left">Entrada P</TableCell>
                            <TableCell style={{width: '80px'}} className={classes.tableHead} align="left">Entrada R</TableCell>
                            <TableCell style={{width: '80px'}} className={classes.tableHead} align="left">Salida P</TableCell>
                            <TableCell style={{width: '80px'}} className={classes.tableHead} align="left">Salida R</TableCell>
                            <TableCell className={classes.tableHead} align="left">Supervisor</TableCell>
                            <TableCell className={classes.pinLogoTableCell} align="left">
                                <div className={classes.pinlogoDivContainer}>
                                    <IconButton className={classes.pinLogoIconButton} onClick={goToCurrent}>
                                        <img style={{ height: 24, width: 24 }} src={pinLogo} alt="" />
                                    </IconButton>
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, ind) => (
                            <TableRow key={row.gateMonitorGrillaId} style={row.gateMonitorGrillaPresente === 'Y' ? { backgroundColor: '#EEEEEE' } : null}>
                                <TableCell className={classes.tableBody} component="th" scope="row">
                                    {row.gateMonitorGrillaUsuario}
                                </TableCell>
                                <TableCell className={classes.tableBody} align="left">{row.gateMonitorFecha}</TableCell>
                                <TableCell className={classes.tableBody} align="left">{row.gateMonitorGrillaEntradaP}</TableCell>
                                <TableCell className={classes.tableBody} align="left"><div>{dot(row.gateMonitorGrillaEntradaStatus)} {row.gateMonitorGrillaEntradaR}</div></TableCell>
                                <TableCell className={classes.tableBody} align="left">{row.gateMonitorGrillaSalidaP}</TableCell>
                                <TableCell className={classes.tableBody} align="left"><div>{dot(row.gateMonitorGrillaSalidaStatus)} {row.gateMonitorGrillaSalidaR}</div></TableCell>
                                <TableCell className={classes.tableBody} align="left">{row.gateMonitorGrillaSupervisor}</TableCell>
                                <TableCell className={classes.tableBody} align="left"></TableCell>
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>
            <Websocket url={process.env.REACT_APP_WEBSOCKET_URL} onMessage={handleWebsocketResponse} />
            <Snackbar open={mostrarComo !== null} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                <Alert style={{ width: '1000px' }} onClose={handleClose} severity={mostrarComo ? mostrarComo : ''}>
                    <AlertTitle>{mensajeMostrado[0]}</AlertTitle>
                    {mensajeMostrado[1]}
                </Alert>
            </Snackbar>
        </div>
    );
}

const useStyles = makeStyles({

    mainContainer: {
        height: 'calc(100vh - 147px - 150px)',
    },
    tableContainer: {
        marginLeft: 32,
        height: 'calc(100vh - 147px - 150px)',
        border: 'none',
        width: 'calc(100% - 35px)'
    },
    tableHead: {
        color: '#707070',
        margin: 15,
        borderLeft: 'solid #A7A7A7 1px',
        borderBottom: 'none',
        backgroundColor: 'white',
        height: 5,
    },
    tableBody: {
        height: 54,
        fontSize: 18,
        color: '#707070'
    },
    pinLogo: {
        backgroundColor: '#63C3D1',
        width: 42,
        height: 42,
        borderRadius: 50,
    },
    pinLogoTableCell: {
        backgroundColor: 'white',
        border: 'none'
    },
    pinlogoDivContainer: {
        height: 42,
        width: 42
    },
    pinLogoIconButton: {
        backgroundColor: '#63C3D1',
        height: 42,
        width: 42
    }
});
